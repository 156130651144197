/* eslint-disable camelcase */
import axios from 'axios';
import {
  application,
  apiUrl,
  apigeeConfigApiUrl,
  apigeeConfigApiKey,
  envId
} from '../settings.json';

class ConfigService {
  fetchConfig = async () => {
    const headers = apigeeConfigApiUrl
      ? {
          'content-type': 'application/json',
          Authorization: `Basic ${apigeeConfigApiKey}`
        }
      : {
          'content-type': 'application/json'
        };
    const url = apigeeConfigApiUrl
      ? `${apigeeConfigApiUrl}/${window.REACT_APP_ENV_ID ||
          envId}/${application}`
      : `${apiUrl}/coreapp/configuration/${window.REACT_APP_ENV_ID ||
          envId}/${application}`;
    const { data } = await axios.create()({
      method: 'get',
      url,
      headers
    });
    return {
      ...data,
      fetchDate: Date.now()
    };
  };
  checkSamlAuthorization = async () => {
    try {
      const url = `${apiUrl}/coreapp/saml2/authorize`;
      const Response = await axios.create()({
        method: 'get',
        url,
        withCredentials: true
      });
      return Response.status;
    } catch (error) {
      return 401;
    }
  };

  fetchTaggingSheet = (url) => {
      fetch(`${url}`,{
        method: 'GET',
        withCredentials: true,
        headers:{'content-type': 'application/json'},
        mode: 'no-cors'
        })
        .then(response =>{
          console.log("response from fetch ",response)
          return response
        })
        .catch(e=>{
            console.log(e);
          });
   
  }
    
}

const inst = new ConfigService();
export default inst;
